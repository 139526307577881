@tailwind base;
@tailwind components;

@layer base {
    :root {
        /* Default primary color */
        /* --color-primary: '#336dff'; */
        --color-primary: 51 109 255; /* RGB values for #336dff */
    }
}

@layer components {
  .quill-container {
    .ql-editor {
      min-height: 100px;
      padding: 12px;
      font-size: 14px;
      background: #F9FAFB;
      border-radius: 14px;
      font-family: inherit !important;
    }

    .ql-container {
      border: none;
    }

    .ql-toolbar {
      display: none;
    }
  }

  /* Print Styles */
  @media print {
    .print-styles {
      /* General print styles */
      font-family: 'MyCustomFont', Arial, sans-serif;
      font-size: 12pt; /* Use points for print */
    }

    .print-styles .some-text-element {
      margin-top: 0; /* Reset or adjust margins */
      padding-top: 0; /* Reset or adjust padding */
      line-height: 1.2; /* Adjust line-height if needed */
    }

    .print-styles .another-element {
      /* More specific print styles for other elements */
    }

    /* Hide non-essential elements */
    .no-print,
    [data-pagination],
    [data-header] {
      display: none !important;
    }
  }
}

@tailwind utilities;